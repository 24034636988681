<template>
  <!-- apply to an image --><img src="https://picsum.photos/300" alt="image" />

  <panZoom>
    <img src="https://picsum.photos/300" alt="image" />
  </panZoom>

  <!-- apply to regular dom elements -->
  <panZoom>
    <p>You can zoom me</p>
  </panZoom>

  <!-- apply to svg -->
  <!--  <panZoom selector="#g1">
    <svg height="210" width="400">
      <g id="g1">
        <path d="M150 0 L75 200 L225 200 Z" />
      </g>
    </svg>
  </panZoom>-->
</template>

<script>
import panZoom from 'vue-panzoom'
export default {
  name: 'test-index',
  components: {
    panZoom
  }
}
</script>

<style scoped></style>
